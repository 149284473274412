"use strict";
import {initTagging, updateAndCommitTag} from 'tagging-client';
import * as Bowser from "bowser";
import {init} from "./init";
import {nextStep, nextStepWithFilling, previousStep} from './steps/steps';

export {initTagging, updateAndCommitTag}

declare const window: MyWindow;

// init input validation and tooltip, datePicker if needed
export const device= Bowser.parse(window.navigator.userAgent);
window.init = init;

// provide method we need for tagging
window.updateAndCommitTag=updateAndCommitTag;

// provide methods we need for steps
window.nextStepWithFilling=nextStepWithFilling;
window.nextStep=nextStep;
window.previousStep=previousStep;

window.device = device;