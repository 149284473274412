import {messagesErrorSuffix} from "../validation-rules/validation-rules";
import {ErrorMessage, HTMLCustomInputElement, ValidationRules} from '../models/validation';
import {addClass, removeClass} from '../utils';
import {stepsState} from '../steps/steps';

declare const window: MyWindow;

/**
 *
 * Function use to validate inputs values
 *
 * All the inputs with the attribute : 'validType' are validated
 * The validType can be : password, newPassword, email, client or date
 * If the validType is not in this list, we just check if the input is not empty
 *
 * Rules are defined in a specific file : 'validation-rules'
 * This file can be override by each realm
 *
 * We can specify multiples error messages for each input
 *
 * You can find example in the inputs template file : inputs.ftl
 *
 */
const inputValidation = {

    // Input attribute checked to enable input validation
    attribute: "validType",

    // Inputs required in the current form not validated yet
    invalidInputs: <string[]>[],

    // Rules used to validate inputs value
    validationRules: null,

    // Object to store debounceTime for multiple inputs at the same time
    debounceTime: {},

    // Delay between keypress and input validation
    delay: 500,


    checkInput: (input: HTMLInputElement) => {
        if (!input) {
            return;
        }
        let type: string;
        if (input.parentElement) {
            //major of time since clafouti
            type = input.parentElement.getAttribute(inputValidation.attribute);
        } else {
            //for input calculator
            type = input.getAttribute(inputValidation.attribute);
        }

        // standard message error
        let messages: ErrorMessage[];

        switch (type) {
            case "password":
                messages = inputValidation.checkIfEmpty(input)
                    .concat(inputValidation.validationRules.checkPassword(input));
                break;
            case "calculator":
                messages = inputValidation.checkIfEmpty(input)
                    .concat(inputValidation.validationRules.checkCalculatorPassword(input));
                break;
            case "email":
                messages = inputValidation.validationRules.checkEmail(input);
                if (input.getAttribute('previousValue') !== '') {
                    messages.push(inputValidation.validationRules.checkPreviousValue(input)[0]);
                }
                break;
            case "client":
                messages = inputValidation.checkClient(input);
                break;
            case "date":
                messages = inputValidation.validationRules.checkDate(input);
                break;
            case "newPassword":
                messages = inputValidation.validationRules.checkNewPassword(input);
                break;
            case "newPasswordFlags":
                messages = inputValidation.validationRules.checkNewPassword(input);
                inputValidation.checkNewPasswordFlags(messages, type);
                break;
            case "otpCode":
                messages = inputValidation.validationRules.checkOTPCode(input);
                break;
            default:
                messages = inputValidation.checkIfEmpty(input);
        }

        //if no message, no error
        if (messages == null || messages.length <= 0) {
            return;
        } else {
            let inputInError = false;
            messages.forEach((message: ErrorMessage) => {
                if (message && typeof message.showError == "boolean") {
                    inputInError = inputInError || message.showError;
                    inputValidation.showHideInputError(inputInError, message.showError, input, message.messageSuffix);
                }
            });
            inputValidation.checkInputValidation(inputInError, input);
            return inputInError;
        }
    },

    checkInputValidation: (isInError: boolean, input: HTMLInputElement) => {
        if (!input) {
            return;
        }
        const index = inputValidation.invalidInputs.indexOf(input.name);
        if (isInError && index == -1 && input.name != undefined) {
            inputValidation.invalidInputs.push(input.name);
        } else if (!isInError && index > -1) {
            inputValidation.invalidInputs.splice(index, 1);
        }
        inputValidation.checkIfSubmitBtnDisabled();
        inputValidation.checkIfOtherPasswordFieldsAreEnabled();
    },

    // Disable or enable password-new and password-confirm
    checkIfOtherPasswordFieldsAreEnabled: function () {
        const otherPasswordFieldsIds = ["password-confirm"];
        for (let inputToEnabledId of otherPasswordFieldsIds) {
            const inputToPotentiallyEnabled = <HTMLCustomInputElement>document.getElementById(inputToEnabledId);
            if (!inputToPotentiallyEnabled) {
                return;
            }
            inputToPotentiallyEnabled.disabled = inputValidation.invalidInputs.includes('password-new');
        }
    },

    showHideInputError: (inputInError: boolean, showError: boolean, input: HTMLInputElement, messageSuffix: string) => {
        let id = input.name + messageSuffix;
        let message = document.getElementById(id);

        if (input.parentElement) { //have see only calculator needed this
            if (inputInError) {
                input.parentElement.classList.add("gdt-input--error")
            } else {
                input.parentElement.classList.remove("gdt-input--error")
            }
        }

        if (inputValidation.debounceTime[id]) {
            // Store in input + message avoid clear timeout when multiple message
            clearTimeout(inputValidation.debounceTime[id]);
        }

        if (message && showError) {
            inputValidation.debounceTime[id] = setTimeout(() => {
                addClass(input, "is-invalid");
                removeClass(message, "d-none");

                // We remove keycloak message error to display only input validation errors
                inputValidation.removeKcError();
            }, inputValidation.delay);

        } else if (message) {
            inputValidation.debounceTime[id] = setTimeout(() => {
                if (!inputInError) {
                    removeClass(input, "is-invalid");
                }
                addClass(message, "d-none");
            }, inputValidation.delay);
        }
    },

    checkNewPasswordFlags: function (messages: ErrorMessage[], type: string) {
        if (messages.find((element) => messagesErrorSuffix.NOT_EMPTY === element.messageSuffix)?.showError) {
            messages.forEach((message) => {
                inputValidation.setColorFlagPassword(type + message.messageSuffix, true);
                inputValidation.setIconFlagPassword(type + message.messageSuffix, true);
            });
        } else {
            messages.forEach((message) => {
                inputValidation.setColorFlagPassword(type + message.messageSuffix, message.showError);
                inputValidation.setIconFlagPassword(type + message.messageSuffix, message.showError);
            });
        }
        return messages;
    },

    setColorFlagPassword: function (idFlag: string, error: boolean) {
        if (document.getElementById(idFlag)) {
            document.getElementById(idFlag).getElementsByTagName("gdt-icon")[0].setAttribute("color", error ? "danger-400" : "farmer-600");
        }
    },

    setIconFlagPassword: function (idFlag: string, error: boolean) {
        if (document.getElementById(idFlag)) {
            document.getElementById(idFlag).getElementsByTagName("gdt-icon")[0].setAttribute("name", error ? "cross-circle-fill" : "check-circle-fill");
        }
    },

    // Remove keycloak message error
    removeKcError: () => {
        const errorMessages = document.getElementsByClassName("kc-error-message");
        if (errorMessages && errorMessages[0]) {
            errorMessages[0].remove();
        }
    },

    checkIfEmpty: (input: HTMLInputElement): ErrorMessage[] => {
        const showError = input ? input.value == null || input.value == "" : true;
        return [{showError, messageSuffix: "Message"}];
    },

    // Check if the validation matches at least one of the 3 types of client identifier
    checkClient: (input: HTMLInputElement): ErrorMessage[] => {
        let messages: ErrorMessage[] = [{showError: false, messageSuffix: "Message"}];
        let emailErrorMessage: ErrorMessage[] = inputValidation.validationRules.checkEmail(input);
        let phoneNumberErrorMessage: ErrorMessage[] = inputValidation.validationRules.checkPhoneNumber(input);
        let clientIdentifierErrorMessage: ErrorMessage[] = inputValidation.validationRules.checkClientIdentifier(input);

        // At least one validation has to go through
        if (emailErrorMessage[0].showError && phoneNumberErrorMessage[0].showError && clientIdentifierErrorMessage[0].showError) {
            messages[0].showError = true;
        }

        return messages;
    },

    checkIfSubmitBtnDisabled: function () {
        if (document.querySelector("gdt-button[validation]") != null) {
            const list = document.querySelectorAll("gdt-button[validation]") as NodeListOf<HTMLInputElement>;
            for (let i = 0; list[i]; i++) {
                let button = list[i] as HTMLInputElement;
                if (button.attributes["validation"] != undefined && button.attributes["validation"].value !== '') {
                    button.disabled = inputValidation.invalidInputs.indexOf(button.attributes["validation"].value) > -1;
                } else {
                    button.disabled = inputValidation.invalidInputs.length > 0;
                }
            }
        } else if (document.querySelector("input[validation" + stepsState.stepIndex + "]") != null) {
            (document.querySelector("input[validation" + stepsState.stepIndex + "]") as HTMLInputElement).disabled = inputValidation.invalidInputs.some((inputId) => inputId.includes('step' + stepsState.stepIndex));
        } else if (document.querySelector('gdt-button[type="submit"]') != null) {
            (document.querySelector('gdt-button[type="submit"]') as HTMLInputElement).disabled = inputValidation.invalidInputs.length > 0;
        }
    },

    // Check if the two inputs values are equals
    checkInputsEquals: (inputId1: string, inputId2: string) => {
        const input1 = <HTMLCustomInputElement>document.getElementById(inputId1);
        const input2 = <HTMLCustomInputElement>document.getElementById(inputId2);

        if (input1 != null && input2 != null && (input1.value || input1.dirty) && (input2.value || input2.dirty)) {
            // Set dirty if input modified
            input1.dirty = true;
            input2.dirty = true;

            const areEquals = input1.value === input2.value;
            inputValidation.showHideInputError(!areEquals, !areEquals, input1, "EqualMessage");
            inputValidation.showHideInputError(!areEquals, !areEquals, input2, "EqualMessage");
            inputValidation.checkInputValidation(!areEquals, input1);
            inputValidation.checkInputValidation(!areEquals, input2);
        }
    },

    // Init the check values
    init: (validationRules: ValidationRules) => {
        inputValidation.validationRules = validationRules;

        const createClosure = function (func, input) {
            return function () {
                // Bind inputValidation context to method call
                func.call(inputValidation, input);
            };
        };

        const mutationObserverCleanInput = new MutationObserver((mutationList) => {
            if (
                (mutationList.length == 1 && mutationList[0].removedNodes.length > 0) || //text standard
                (mutationList.length > 1 && mutationList[1].removedNodes.length > 0) || //password case
                (mutationList.length == 1 && (mutationList[0].target as HTMLInputElement).value != "") //autofill old nav who dont trigg input
            ) {
                //on va chercher l'input enfant par son name. Son parent à le meme name que l'input qui nous interesse
                const gdtInput : HTMLCustomInputElement = mutationList[0].target as HTMLCustomInputElement;
                inputValidation.checkInput(gdtInput.children[gdtInput.name] as HTMLInputElement);
            }
        });
        inputValidation.maskSaisiOtp();
        let gdtInputs = document.getElementsByTagName("gdt-input");
        let inputs = document.getElementsByTagName("input");
        if (inputs.length < gdtInputs.length) {
            //Cas ou les gdt input n'ont pas encore leurs petits : on doit catcher le moment ou le script clafouti les
            //ajoute pour rebind les event pour le controle de formulaire
            const mutationObserverAddInput = new MutationObserver((mutationList) => {
                Array.from(mutationList).forEach((mutation) => {
                    Array.from(mutation.addedNodes).forEach((addedNode) => {
                        if (addedNode.nodeName == 'INPUT') {
                            addedNode.addEventListener("input", createClosure(inputValidation.checkInput, addedNode), false);
                        }
                    });
                    inputValidation.checkForm();
                    mutationObserverAddInput.disconnect();
                });
            });
            Array.from(gdtInputs).forEach((input) => {
                mutationObserverAddInput.observe(input, {childList: true});
            });

        } else {
            //Cas ou le tout les inputs sont a leurs place, on peut avancer comme d'hab

            //Pour les controles de completion du formulaire
            Array.from(inputs).forEach((input) => {
                input.addEventListener("input", createClosure(inputValidation.checkInput, input), false);
            });
        }

        //Pour le clear de gdt-input et l'auto fill
        Array.from(gdtInputs).forEach((input) => {
            mutationObserverCleanInput.observe(input, {childList: true});
        });

        inputValidation.checkForm();
    },
    checkForm: () => {
        Array.from(document.getElementsByTagName("input")).forEach((input) => {
            const index = inputValidation.invalidInputs.indexOf(input.name);
            if ((input ? input.value == null || input.value == "" : true) && index == -1 && input.name != "" && input.name != "adobe_mc") {
                inputValidation.invalidInputs.push(input.name);
            }
        });
        inputValidation.checkIfSubmitBtnDisabled();
        inputValidation.checkIfOtherPasswordFieldsAreEnabled();
    },
    maskSaisiOtp() {
        let elem: any = document.getElementById('otp');
        if (elem) {
            elem.mask = {mask: "000000", lazy: false, overwrite: 'shift'};
        }
    }
};

// Append text to input element + add input password discs
// Use for calculator login form
window.appendTextInputCalculator = (text: string, id: string) => {
    const input = document.getElementById(id) as HTMLInputElement;
    if (input) {
        input.value = input.value + text;
        if (input.value.length >= 1 && input.value.length <= 6) {
            const selectDisc = document.getElementById(id + "-disc-container").children[input.value.length - 1] as HTMLInputElement;
            addClass(selectDisc, "on");
        }

        inputValidation.checkInput(input);
    }
};

window.checkInputsEquals = (inputId1: string, inputId2: string) => {
    inputValidation.checkInputsEquals(inputId1, inputId2);
};

//it is possible to capture event when browser autofill input with this kind of hack
//https://github.com/klarna/ui/blob/v4.10.0/Field/styles.scss#L228-L241 -webkit-autofill css trig animation (animation-name: onAutoFillStart;)
//https://github.com/klarna/ui/blob/v4.10.0/Field/index.jsx#L104-L114 animationstart event is capture maybe use animation name to do something

export default inputValidation;
