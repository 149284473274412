"use strict";

export const stepsState = {
    stepIndex: 1
};

export const nextStepWithFilling = (idToCopy: string, idToFill: string) => {
    document.getElementById(idToFill).innerHTML =  "Votre identifiant : <br>" + (<HTMLInputElement>document.getElementById(idToCopy)).value;
    stepsState.stepIndex = stepsState.stepIndex + 1;
    toggleStepForm();
};

export const nextStep = () => {
    stepsState.stepIndex = stepsState.stepIndex + 1;
    toggleStepForm();
};

export const previousStep = () => {
    stepsState.stepIndex = stepsState.stepIndex > 1 ? stepsState.stepIndex - 1 : 1;
    toggleStepForm();
};

let toggleStepForm = function() {
    const forms = document.getElementsByClassName("form-step") as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < forms.length; i++) {
        if (i + 1 !== stepsState.stepIndex) {
            forms[i].style.display = "none";
        } else {
            forms[i].style.display = "block";
        }
    }
};
