/**
 * Method that checks whether cls is present in element object.
 * @param  {HTMLElement} ele DOM element which needs to be checked
 * @param  {string} cls Classname is tested
 * @return {Boolean} True if cls is present, false otherwise.
 */
export const hasClass = (ele : HTMLElement, cls : string) : boolean => {
    return ele.getAttribute('class').indexOf(cls) > -1;
};
  
  
/**
* Method that adds a class to given element.
* @param  {HTMLElement} ele DOM element where class needs to be added
* @param  {string} cls Classname which is to be added
* @return {null} nothing is returned.
*/
export const addClass= (ele : HTMLElement, cls : string) : void => {
    if (ele.classList) {
        ele.classList.add(cls);
    } else if (!hasClass(ele, cls)) {
        ele.setAttribute('class', ele.getAttribute('class') + ' ' + cls);
    }
};

/**
* Method that does a check to ensure that class is removed from element.
* @param  {HTMLElement} ele DOM element where class needs to be removed
* @param  {string} cls Classname which is to be removed
* @return {null} Null nothing is returned.
*/
export const removeClass = (ele : HTMLElement, cls : string) : void => {
    if (ele.classList) {
        ele.classList.remove(cls);
    } else if (hasClass(ele, cls)) {
        ele.setAttribute('class', ele.getAttribute('class').replace(cls, ' '));
    }
};
  